.main-center{
    width: 100vw;
    height: 90%;
    position: absolute;
    left:50%;
    transform: scale(1.2) translateX(-50%);
}

.absolute{
    position: absolute;
}

.img{
    transform: translateX(-1px);
}