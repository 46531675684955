body {
  background-color: black !important;
  overflow-x: hidden;
}

.bg-trans{
  background-color: transparent !important;
}

.bg-card{
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bg-blur{
  filter: blur(15px);
}

.text-shadow{
  text-shadow: 2px 4px 3px #000000;
}

.no-decoration{
  text-decoration: none !important;
}

.mouse-zoom-in-sm{
  transition: all .2s ease-in-out;
}

.mouse-zoom-in-sm:hover{
  transform: scale(1.1) !important;
}

.mouse-zoom-in{
  transition: all .2s ease-in-out !important;
}

.mouse-zoom-in:hover{
  transform: scale(1.2);
}

.mouse-zoom-in-lg{
  transition: all .2s ease-in-out;
}

.mouse-zoom-in-lg:hover{
  transform: scale(1.5);
}

.focus{
  transition: all .2s ease-in-out;
}
.focus:focus{
  transform: scale(1.5);
}


.transition-1s{
  transition: all 1s ease-in-out;
}

.hover-text-shadow > div > a > div > div.card-title {
  transition: all 1s;
}

.hover-text-shadow:hover > div > a > div > div.card-title {
  transform: translateY(-5px);
  text-shadow: 8px 9px 3px #000000;
}

.hover-translucid{
  transition: all 0.1s;
  filter: opacity(0.5);
}
.hover-translucid:hover{
  filter: opacity(1);
}

@media (max-width:600px){
  .carousel {
    min-width: 100%;
  }
  .carousel-item {
    min-height: 280px !important;
  }
}
@media (min-width:601px){
  .carousel {
    width: 500px;
  }
}

@font-face {
  font-family: 'Pacifico';
  src: url('./css/fonts/pacifico-regular-webfont.woff2') format('woff2'),
       url('./css/fonts/pacifico-regular-webfont.woff') format('woff');
}